.wrapper {
  padding-top: 0;
}

.modal {
  padding: 24px 15px !important;
}

.title {
  margin-bottom: 0;
}

.info {
  margin-bottom: 24px !important;
}

.uploadZone {
  margin-bottom: 30px;
}

.container {
  max-width: 1280px;
  margin-top: 12px;
}

.fileList {
  display: grid;
  gap: 30px;
  grid-template-columns: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;

  margin-top: 30px;
}

.buttons button:last-child {
  margin-left: 15px;
}

.column {
  display: grid;
  gap: 7px;
}

.additionalInfo {
  color: var(--palette-text-default);

  font-size: 13px;
  font-style: normal;
  line-height: 130%;
}

.additionalInfo span {
  font-weight: 500;
}

.tabsWrapper {
  max-width: 300px;
}

.tabs {
  width: 100%;
  border: 1px solid #eaeaea;
}
