.card {
  height: 40px;
  padding: 0 24px;
  border-radius: 12px;
  border: 1px solid var(--palette-border-primary);
  box-shadow: var(--palette-box-shadow);
  background-color: #fff;
  cursor: pointer;
  transition: 0.2s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  justify-content: center;
  color: var(--palette-text-secondary);

  &:hover {
    background-color: var(--palette-border-primary);
  }

  svg,
  path {
    fill: var(--palette-primary);
  }

  span {
    font-size: 12px;
  }
}

.row {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
}
