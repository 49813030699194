.modalContent {
  padding: 16px 24px 24px 24px !important;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
  gap: 12px;

  @media screen and (min-width: 992px) {
    display: flex;
  }
}

.tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  &:first-child {
    border-top-left-radius: 0 !important;
  }
}

.tabButton {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 1px solid #eaeaea;
}

.label {
  font-size: 16px;
  font-weight: 500;
}

.searchWrapper {
  max-width: 300px;
}

.filtersWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tabs {
  margin: 12px 0;

  @media screen and (min-width: 1200px) {
    margin: 0;
  }
}

.search {
  div {
    height: 32px !important;

    input {
      height: 32px !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.content {
  width: 100%;
  margin-top: 6px;
}

.pagination {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 16px;
}

.table {
  margin-top: 12px;
  border-radius: 8px;
}

.tableContent {
  row-gap: 0 !important;
  min-height: unset !important;
}

.badge {
  border: 1px solid #cacaca;
}
