.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
}

.background {
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--palette-text-primary);
}

.title {
  margin: 0;
  font-weight: 600;
}

.description {
  margin: 0;
  color: var(--palette-text-secondary);
}
