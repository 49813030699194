.title {
  font-size: 22px;
  font-weight: 500;
}

.labelRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
}

.projectDescription {
  margin: 0 0 8px 0;
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.description {
  @extend .projectDescription;
  margin: 6px 0;
  font-size: 13px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.clientIdField {
  margin-top: 7px;
}

.inputRow {
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  gap: 10px;
}

.downloadButton {
  width: 40px;
  height: 40px;
  background-color: var(--palette-primary);
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-top: -6px;
  transition: 0.2s ease;

  &:disabled {
    opacity: 0.6;
    cursor: auto;
    pointer-events: none;
  }

  svg {
    color: #fff;
  }

  &:hover {
    background-color: var(--palette-primary-hover);
  }
}
