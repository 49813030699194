.container {
  padding: 12px 48px 48px 48px !important;
}

.errorWrapper {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

.title {
  font-size: 24px;
  margin-bottom: 0;
}

.description {
  color: var(--palette-text-secondary);
  font-size: 14px;
}
