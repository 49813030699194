@import url("non.geist");

.container {
  display: flex;
  overflow: auto;
  align-items: center;
  position: relative;

  width: 100vw;
  max-width: 100%;
  min-height: 100vh;

  background: var(--palette-default);
}

/* Logo */

.logo {
  position: absolute;
  z-index: 15;
  top: 40px;
  left: 40px;

  display: flex;
  align-items: center;
}

.logo .icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  margin-right: 12px;
  padding: 9px;

  border-radius: 12px;
  background: var(--palette-primary);
}

.logoWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }
}

.logoText {
  color: var(--palette-text-default);
  font-family: "Geist Variable", sans-serif;
  font-size: 24px;
  font-weight: 900;
}

.gradientBg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: cover;
  width: 100vw;
  height: 100vh;

  opacity: 0.8;
}

/* Right Side Form */

.loginForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 0;
  border-left: 1px solid var(--palette-border-primary);

  width: 50%;
  height: 100vh;

  @media (max-width: 992px) {
    width: 100%;
  }

  form {
    width: calc(100% - 120px);
    max-width: 563px;

    @media (max-width: 992px) {
      width: calc(100% - 80px);
    }
  }

  .forgotPassword {
    margin-top: -10px;
    margin-bottom: 24px;
    margin-left: auto;

    cursor: pointer;
    text-align: right;

    color: var(--palette-primary);
  }
}

.formTitle {
  margin-bottom: 0;

  color: var(--palette-text-default);

  font-size: 22px;
  font-weight: 500;
  font-style: normal;
}

.formSubtitle {
  margin-bottom: 24px;
  color: var(--palette-text-secondary);

  font-weight: normal;
  font-style: normal;
}

/* Submit */

.button {
  width: 140px;
  margin: 0 0 0 auto;
}

.loader {
  position: relative;
  margin-top: 24px;
}

.errorModal {
  padding: 24px;

  h1 {
    margin: 12px 0 6px 0;
  }

  p {
    font-size: 14px;
    margin: 0;
    color: var(--palette-text-secondary);
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--palette-text-secondary);
}

.buttonWrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
