.lightTheme {
  /* Color Palette ↓ */
  /* Make sure you add changes from palette to theme.stories.tsx */
  --palette-body: #f1f1f1;
  --palette-backdrop: rgba(0, 21, 97, 0.18);

  --palette-text-default: #2d2d2d;
  --palette-text-primary: #414656;
  --palette-text-secondary: #747b8b;
  --palette-text-link: #317fd1;

  --palette-border-primary: rgba(0, 0, 0, 0.1);
  --palette-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);

  --palette-default: #fff;
  --palette-default-focus: #f1f5f9;
  --palette-default-active: #ebf2f9;
  --palette-default-hover: #fafafa;
  --palette-default-disabled: #ebe9e9;
  --palette-default-transparent: rgba(255, 255, 255, 0.4);

  --palette-primary: #217ee2;
  --palette-primary-focus: #1a65b5;
  --palette-primary-active: #17589e;
  --palette-primary-hover: #1e71cb;
  --palette-primary-disabled: #d1d4df;
  --palette-primary-transparent: #e3ecfa;
  --palette-primary-sortable-hover: #354052;

  --palette-secondary: #bfcde0;
  --palette-secondary-focus: #9aaec9;
  --palette-secondary-active: #8a9cb3;
  --palette-secondary-hover: #a1b7d4;
  --palette-secondary-transparent: #ecf0f6;

  --palette-tertiary-focus: #a5abbd;
  --palette-tertiary-hover: #7f8992;
  --palette-tertiary-disabled: #edeef2;
  --palette-tertiary-transparent: #e4e6ec;

  --palette-grey-main: #758391;
  --palette-grey-focus: #596473;
  --palette-grey-active: #5e6875;
  --palette-grey-hover: #9aa5af;
  --palette-grey-disabled: #e0e3e6;
  --palette-grey-stoke: #e2e7eb;
  --palette-grey-input-focus: #eceef1;
  --palette-grey-table-bg: #f4f5f7;
  --palette-grey-lightly: #f8f9fa;

  --palette-error: #e0482d;
  --palette-error-focus: #9d321f;
  --palette-error-hover: #e97f6c;
  --palette-error-transparent: #f6cac2;

  --palette-success: #00984c;
  --palette-success-active: #003d1e;
  --palette-success-hover: #4db782;
  --palette-success-transparent: #b5e1cb;

  --palette-warning: #db9a2e;
  --palette-warning-focus: #ac821d;
  --palette-warning-hover: #f9cf6a;
  --palette-warning-transparent: #fce9bd;

  --palette-info: #477ed2;
  --palette-info-focus: #4071bd;
  --palette-info-active: #3965a8;
  --palette-info-hover: #598bd7;
  --palette-info-transparent: #bbd0ef;

  --palette-shadow-tiny: rgba(49, 75, 116, 0.08);
  --palette-shadow-small: rgba(49, 75, 116, 0.15);
  --palette-shadow-medium: rgba(49, 75, 116, 0.18);
  --palette-shadow-big: rgba(49, 75, 116, 0.25);
  --palette-shadow-large: rgba(49, 75, 116, 0.4);

  /* ------------------------------------------------------------------------------------------ */

  /* Index Styles ↓ */
  --body-color: var(--palette-text-default);
  --body-background-color: var(--palette-body);
  /* Index Styles ↑ */

  /* Global Styles ↓ */
  --global-scroll-background-color: #cfd1d8;
  --global-scroll-background-hover-color: #b6b9c5;
  --global-scroll-background-active-color: #9fa4b2;
  --global-scroll-width: 10px;
  --global-scroll-height: var(--global-scroll-width);
  /* Global ↑ */

  /* Shadows ↓ */
  --shadow-tiny: 0 4px 4px var(--palette-shadow-tiny);
  /* Shadows ↑ */

  /* Button ↓ */
  /* Button Primary Appearance */
  --button-primary-color: var(--palette-default);

  --button-primary-background: var(--palette-primary);
  --button-primary-hover-background: var(--palette-primary-hover);
  --button-primary-focus-background: var(--palette-primary-focus);
  --button-primary-active-background: var(--palette-primary-active);
  --button-primary-disabled-background: var(--palette-primary-disabled);

  /* Button Secondary Appearance */
  --button-secondary-color: var(--palette-primary);
  --button-secondary-disabled-color: var(--palette-primary-disabled);

  --button-secondary-background: var(--palette-default);
  --button-secondary-hover-color: var(--palette-primary-hover);
  --button-secondary-hover--border: var(--palette-primary-hover);
  --button-secondary-focus-color: var(--palette-primary-focus);
  --button-secondary-focus-border: var(--palette-primary-focus);
  --button-secondary-active-color: var(--palette-primary-active);
  --button-secondary-active-border: var(--palette-primary-active);
  --button-secondary-disabled-border: var(--palette-primary-disabled);

  /* Button Text Appearance */
  --button-text-color: var(--palette-text-primary);
  --button-text-hover-color: var(--palette-primary-hover);
  --button-text-focus-color: var(--palette-primary-focus);
  --button-text-active-color: var(--palette-primary-active);
  --button-text-disabled-color: var(--palette-primary-disabled);

  /* Button Transparent Appearance */
  --button-transparent-color: var(--palette-primary);
  --button-transparent-hover-color: var(--palette-primary-hover);
  --button-transparent-focus-color: var(--palette-primary-focus);
  --button-transparent-active-color: var(--palette-primary-active);
  --button-transparent-effect-background: var(--palette-primary-transparent);
  --button-transparent-disabled-color: var(--palette-primary-disabled);
  /* Button ↑ */

  /* Input ↓ */
  /* Input Primary Appearance */
  --input-color: var(--palette-text-primary);
  --input-placeholder-color: var(--palette-text-secondary);
  --input-background: var(--palette-default);
  --input-border: var(--palette-secondary);
  --input-per-page-border: var(--input-border);

  --input-caret-focus: var(--input-color);
  --input-border-hover: var(--palette-secondary-hover);
  --input-border-focus: var(--palette-primary-focus);
  --input-border-error: var(--palette-error);

  --input-color-disabled: var(--palette-primary-disabled);
  --input-background-disabled: var(--palette-grey-lightly);
  --input-border-disabled: var(--palette-primary-disabled);
  /* Input ↑ */

  /* Date Input ↓ */
  /* Date Input ↓ */
  --date-input-clock-intervals-wrapper-shadow: var(--shadow-tiny);
  --date-input-clock-intervals-option-hover: var(--palette-default-hover);
  /* Date Input ↑ */

  /* Select ↓ */
  --selected-options-chip-color: var(--palette-primary);
  --selected-options-chip-background: var(--palette-default-hover);
  /* Select ↑ */

  /* Label ↓ */
  --label-color: var(--palette-text-secondary);
  --label-filled-color: var(--palette-primary-focus);
  --label-floating-background: var(--palette-default);
  /* Label ↑ */

  /* Radio ↓ */
  --radio-background-default-color: var(--palette-default);
  --radio-background-disabled-color: var(--palette-primary-transparent);
  --radio-label-default-color: var(--palette-text-primary);

  --radio-border-color: var(--palette-primary-disabled);
  --radio-border-disabled-color: var(--palette-primary-disabled);
  --radio-border-hover-color: var(--palette-primary-hover);
  --radio-border-focus-color: var(--palette-primary-focus);
  --radio-border-selected-color: var(--palette-primary);
  /* Radio ↑ */

  /* Range ↓ */
  /* Range Primary Appearance */
  --range-slider-background-primary: var(--palette-default);
  --range-controller-background-primary: var(--palette-default);
  --range-progress-background-primary: var(--palette-primary);
  --range-progress-background-primary-active: var(--palette-primary-hover);

  /* Range Secondary Appearance */
  --range-slider-background-secondary: var(--palette-default-disabled);
  --range-controller-background-secondary: var(--palette-default);
  --range-progress-background-secondary: var(--palette-secondary);
  --range-progress-background-secondary-active: var(--palette-secondary-hover);

  /* Range Shadow Styles */
  --range-controller-shadow: -1px -1px 7px var(--palette-shadow-small), 2px 2px 7px var(--palette-shadow-small);
  --range-controller-shadow-active: 0 0 8px var(--palette-shadow-big), 0 0 8px var(--palette-shadow-big);
  /* Range ↑ */

  /* Checkbox ↓ */
  --checkbox-label-color: var(--palette-text-primary);
  --checkbox-disabled-label-color: var(--palette-primary-disabled);

  --checkbox-background: var(--palette-default);
  --checkbox-border: var(--palette-primary-disabled);

  --checkbox-background-disabled: var(--palette-primary-transparent);
  --checkbox-background-checked: var(--palette-primary);
  --checkbox-border-hover: var(--palette-primary-hover);
  --checkbox-border-focus: var(--palette-primary-focus);
  --checkbox-border-active: var(--palette-primary-active);
  /* Checkbox ↑ */

  /* Switch ↓ */
  --switch-label-color: var(--palette-text-primary);
  --switch-label-disabled-color: var(--palette-primary-disabled);

  --switch-background-primary-checked: var(--palette-primary);
  --switch-background-primary-checked-disabled: var(--palette-primary-transparent);
  --switch-background-primary-unchecked: var(--palette-primary-disabled);
  --switch-background-primary-unchecked-disabled: var(--palette-primary-disabled);

  --switch-toggle-background: var(--palette-default);
  --switch-disabled-img-color: var(--palette-primary-disabled);
  --switch-disabled-img-checked-color: var(--palette-primary-transparent);

  /* Switch ↑ */

  /* Filters ↓ */
  --filters-default-color: var(--palette-text-secondary);
  --filters-opener-background: var(--palette-default);
  --filters-opener-disabled-background: var(--palette-grey-lightly);

  --filters-border-color: var(--palette-secondary);
  --filters-border-hover-color: var(--palette-secondary-hover);

  --filters-focus-color: var(--palette-primary-focus);
  --filters-disabled-color: var(--palette-primary-disabled);

  --filters-text-hover-color: var(--palette-primary-hover);

  --filters-section-background: var(--palette-tertiary-transparent);
  /* Filters ↑ */

  /* Pagination ↓ */
  --pagination-color: var(--palette-default);

  --pagination-button-hover: var(--palette-primary-hover);
  --pagination-button-focus: var(--palette-primary-focus);
  --pagination-button-active: var(--palette-primary-active);
  --pagination-button-arrow-background: var(--palette-default-focus);
  --pagination-button-arrow-background-active: var(--palette-primary-transparent);
  --pagination-button-svg-background: var(--palette-tertiary-focus);
  --pagination-button-svg-background-disabled: var(--palette-primary-disabled);
  --pagination-select-border-color: var(--palette-primary-disabled);
  --pagination-button-selected: var(--palette-primary);

  --pagination-color-default: var(--palette-text-primary);
  --pagination-background-default: var(--palette-default);
  /* Pagination ↑ */

  /* Drawer ↓ */
  --drawer-background: var(--palette-default);

  --drawer-backdrop: var(--palette-backdrop);
  --drawer-box-shadow: -2px -2px 8px var(--palette-shadow-medium), 2px 2px 8px var(--palette-shadow-medium);
  /* Drawer ↑ */

  /* Modal ↓ */
  --modal-background: var(--palette-default);
  --modal-backdrop: var(--palette-backdrop);
  --modal-shadow-tiny: var(--shadow-tiny);
  /* Modal ↑ */

  /* Date Picker ↓ */
  --date-picker-background: var(--palette-default);
  --date-picker-week-name-color: var(--palette-text-secondary);
  --date-picker-text-hover-color: var(--palette-text-primary);
  --date-picker-present-date-color: var(--palette-primary);
  --date-picker-out-boundaries-date-color: var(--palette-secondary);

  --date-picker-selected-color: var(--palette-default);
  --date-picker-selected-background-color: var(--palette-primary);

  --date-picker-day-hover: var(--palette-default-hover);
  --date-picker-day-focus: var(--palette-primary-focus);
  --date-picker-text-day-focus: var(--palette-default);
  /* Date Picker ↑ */

  /* Draggable-Dropdown-List ↓ */
  --draggable-option-background: var(--palette-default);
  --draggable-option-background-hover: var(--palette-default-hover);
  --draggable-option-background-focus: var(--palette-default-focus);
  --draggable-dragging-option-background: var(--palette-default-focus);
  --draggable-drop-line: var(--palette-primary);
  /* Draggable-Dropdown-List ↑ */

  /* Table ↓ */
  --table-cell-background-color: var(--palette-default);
  --table-header-cell-info-color: var(--palette-text-secondary);
  --table-header-cell-sortable-hover-color: var(--palette-primary-sortable-hover);

  --table-cell-background-color-hover: var(--palette-default-hover);
  --table-cell-background-selected: var(--palette-default-active);
  --table-cell-background-selected-hover: var(--palette-default-hover);

  --table-row-has-collapse-shadow: 0px 4px 6px -4px var(--palette-shadow-small);
  --table-row-collapse-background: var(--palette-primary-disabled);
  /* Table ↑ */

  /* Tooltip ↓ */
  --tooltip-background: var(--palette-default);
  --tooltip-text-color: var(--palette-text-primary);

  --tooltip-box-shadow: 0 4px 4px var(--palette-shadow-medium);
  /* Tooltip ↑ */

  /* Tabs ↓ */
  --tab-color: var(--palette-text-secondary);
  --tab-background: var(--palette-body);

  --tab-selected-color: var(--palette-text-primary);
  --tab-selected-background: var(--palette-default);

  --tab-dropdown-background: var(--palette-default);
  --tab-dropdown-background-hover: var(--palette-default-active);
  --tab-dropdown-background-selected: var(--palette-default-active);

  --tab-hover-background: var(--palette-grey-table-bg);
  --tab-icon-hover-fill: var(--palette-text-secondary);
  --tab-box-shadow: 4px 4px 20px var(--palette-shadow-tiny);
  --tab-dropdown-wrapper-shadow: 0 0 10px var(--palette-shadow-tiny);
  --tab-dropdown-shadow: -1px -1px 7px var(--palette-shadow-tiny), 2px 2px 7px var(--palette-shadow-tiny);
  --tab-add-new-background: var(--palette-default);
  /* Tabs ↑ */

  /* Notification ↓ */
  --notification-title-color: var(--palette-text-primary);
  --notification-subtitle-color: var(--palette-text-secondary);
  --notification-background: var(--palette-default);
  --notification-shadow: var(--shadow-tiny);
  /* Notification ↑ */

  /* MenuButton ↓ */
  --menu-button-background-hover: var(--palette-default-hover);
  --menu-button-color: var(--palette-text-primary);
  --menu-button-shadow: var(--shadow-tiny);
  /* MenuButton ↑ */

  /* Errors Pages ↓ */
  --errors-pages-background-color: var(--palette-default);

  --errors-pages-title-color: var(--palette-text-default);
  --errors-pages-subtitle-color: var(--palette-text-secondary);
  /* Errors Pages ↑ */

  /* Chip ↓ */
  --chip-background-disabled: var(--palette-default-disabled);
  --chip-color-disabled: var(--palette-text-secondary);

  /* Chip ↑ */

  /* TreeFileTabs ↓ */
  --tree-file-tabs-container-background-color: var(--palette-default);
  --tree-file-tree-wrapper-border-right-color: var(--palette-primary-disabled);
  --tree-file-tree-wrapper-title: var(--palette-text-primary);
  --tree-file-tree-text: var(--palette-text-primary);
  --tree-file-tree-item-non-clickable-name: var(--palette-primary-disabled);
  --tree-file-tree-item-name: var(--palette-text-secondary);
  --tree-file-tree-item-selected-name: var(--palette-text-default);
  /* TreeFileTabs  Unique Styles */
  /* TreeFileTabs ↑ */

  /* File Upload ↓ */
  --file-upload-background: var(--palette-default);
  --file-upload-background-active: var(--palette-default-hover);

  --file-upload-border: var(--palette-primary-disabled);
  --file-upload-in-drop-zone-border: var(--palette-primary);

  --file-upload-browse-text-ready-color: var(--palette-primary);
  --file-upload-label-color: var(--palette-text-primary);
  --file-upload-subtitle-color: var(--palette-text-secondary);
  /* File Upload Unique Styles */
  /* File Upload ↑ */

  /* File Item ↓ */
  --file-item-background: var(--palette-default);

  --file-item-border: var(--palette-tertiary-transparent);

  --file-item-progress-info-color: var(--palette-text-secondary);

  --file-item-loader-wrapper-background: var(--palette-primary-disabled);
  --file-item-loader-bar-background: var(--palette-primary);

  /* File Card Type*/
  --file-item-card-title-status-color: var(--palette-text-secondary);
  --file-item-card-bottom-top-border: var(--palette-tertiary-transparent);

  /* File Row Type*/
  --file-item-row-info-color: var(--palette-text-secondary);

  /* File Item Unique Styles */
  --file-item-hover-shadow: 0 -3px 5px var(--palette-shadow-tiny), 0 3px 5px var(--palette-shadow-tiny);
  /* File Item ↑ */

  /* Sidebar ↓ */
  --sidebar-toggler-background: var(--palette-secondary);
  --sidebar-background: var(--palette-default);
  /* Sidebar ↓ */
}
