@import url("non.geist");

.container {
  width: 100%;
  padding: 16px 24px;
  border-radius: 16px;
  background: var(--palette-default);
  border: 1px solid var(--palette-border-primary);
  box-shadow: var(--palette-box-shadow);
  overflow: hidden;
}

.labelWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-size: 16px;
  font-weight: 500;
}

.infoWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 12px;

  @media screen and (min-width: 1400px) {
    gap: 12px;
  }

  @media screen and (min-width: 1500px) {
    gap: 24px;
  }
}

.title {
  font-family: "Geist Variable", sans-serif;
  margin: 0;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}

.skeleton {
  margin: 14px 0 0 0;
}

.completed {
  color: var(--palette-success);
  font-size: 14px;
  white-space: nowrap;

  @media screen and (min-width: 992px) {
    font-size: 11px;
  }

  @media screen and (min-width: 1104px) {
    font-size: 14px;
  }
}

.failed {
  color: var(--palette-error);
  white-space: nowrap;

  @media screen and (min-width: 992px) {
    font-size: 11px;
  }

  @media screen and (min-width: 1104px) {
    font-size: 14px;
  }
}

.waiting {
  color: var(--palette-text-secondary);
  white-space: nowrap;

  @media screen and (min-width: 992px) {
    font-size: 11px;
  }

  @media screen and (min-width: 1104px) {
    font-size: 14px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.description {
  font-size: 12px;
  color: var(--palette-text-secondary);
}
