.name {
  font-weight: 600;
}

.tableRow {
  cursor: pointer;
}

.cellRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
