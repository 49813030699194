.container {
  position: relative;

  flex: 1 1 auto;

  width: 100%;
  max-width: 100vw;
  padding: 24px 48px;

  @media (max-width: 768px) {
    padding: 20px 24px;
  }
}

.content {
  width: 100%;
  margin: 0 auto;
}
