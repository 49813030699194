.row {
  cursor: pointer;
}

.name {
  font-weight: 600;
}

.switch {
  display: flex;
  align-items: center;
}

.caption {
  margin: 0;
  color: var(--palette-text-secondary);
  font-size: 11px;
  font-weight: 400;
}

.textWrapper {
  line-height: 14px;
}
