.title {
  font-size: 22px;
  font-weight: 500;
}

.labelRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
}

.projectDescription {
  margin: 0 0 8px 0;
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.description {
  @extend .projectDescription;
  margin: 6px 0;
  font-size: 13px;
}

.labelDescription {
  font-size: 12px;
  color: var(--palette-text-secondary);
  margin: 0 0 4px 0;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.clientIdField {
  margin-top: 7px;
}
