.header {
  display: flex;
  gap: 20px;

  justify-content: space-between;

  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 6px;
}

.notActiveText {
  color: var(--palette-text-secondary);
  font-size: 14px;
  padding-top: 12px;
}

.switch {
  margin-top: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
