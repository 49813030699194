.title {
  font-size: 22px;
  font-weight: 500;
}

.subtitle {
  font-size: 13px;
  line-height: 1.3;
  color: var(--palette-text-secondary);
  margin: 6px 0;
}

.actions {
  display: flex;
  justify-content: flex-end;

  button:first-of-type {
    margin-right: 10px;
  }
}

.row {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
}

.buttons {
  margin-top: 24px;
}

.labelRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
}

.alignLabelRow {
  @extend .labelRow;
  margin-bottom: 8px;
}

.projectDescription {
  margin: 0 0 8px 0;
  color: var(--palette-text-secondary);
  font-size: 12px;
}
