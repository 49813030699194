.container {
  width: 40px;
  height: 40px;
  background: linear-gradient(45deg, rgba(123, 120, 185, 1) 0%, rgba(128, 128, 221, 1) 41%, rgba(0, 212, 255, 1) 100%);

  border-radius: 50%;
  display: grid;
  place-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
}

.image {
  width: 40px;
  height: 40px;

  border-radius: 50%;
  object-fit: cover;
}
