.wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 11px;
}

.container {
  width: 100%;
  min-width: 100px;
  max-width: 300px;
  height: 3px;
  background-color: var(--palette-grey-stoke);
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.inner {
  background-color: var(--palette-success);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 2px;
}

.values {
  font-size: 11px;
  color: var(--palette-grey-main);
}

.percentage {
  font-size: 12px;
  color: var(--palette-success);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
