.header {
  display: flex;
  gap: 20px;

  justify-content: space-between;

  margin-bottom: 24px;
}

.tableHeader > .headerRow {
  &:first-child {
    div:first-child {
      margin-left: 82px;
    }
  }
}

.notActiveText {
  color: var(--palette-text-secondary);
  font-size: 14px;
  padding-top: 12px;
}

.switch {
  margin-top: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.deleteButton {
  background-color: transparent;
  color: var(--palette-text-primary);

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--palette-text-primary);
    }
  }

  &:hover {
    //background-color: hsl(240 4.8% 95.9%);
    background-color: #eaeaea;

    svg {
      path {
        fill: var(--palette-text-primary);
      }
    }
  }

  &:focus {
    background-color: #eaeaea;
  }
}
