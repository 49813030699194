.checkboxCell {
  min-width: 60px !important;
  max-width: 60px;
  height: calc(100% + 24px);
  margin: -12px 0;
  padding: 0 !important;

  div:first-child {
    justify-content: center;

    width: 100%;
    height: 100%;
  }
}

.checkbox {
  width: 100%;
  height: 100%;
}

.firstCell {
  padding-left: 0 !important;
  font-weight: 700;
}

.switch {
  display: flex;
  align-items: center;
}
