@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("non.geist");

body {
  overflow-x: hidden;
  overflow-y: auto;

  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;

  color: var(--palette-text-default);
  background: var(--palette-body);

  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;

  outline: none;

  //font-family: "Geist Variable", sans-serif;
  font-family: "Poppins", sans-serif;
  -webkit-user-drag: none;
}

img,
img *,
svg,
svg * {
  user-select: none;
}
