.wrapper {
  width: 100%;
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 12px;
}

.projectName {
  font-weight: 600;
}

.labelsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.labelRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
}

.labelSpan {
  font-size: 11px;
  color: var(--palette-text-secondary);
  margin: 0;
}

.input {
  width: 100%;
}

.removeButton {
  background-color: var(--palette-error-transparent) !important;
  color: var(--palette-error) !important;
  border-radius: 8px;
  display: grid;
  place-items: center;
  width: 32px !important;
  height: 32px !important;
  margin-top: 8px;
  transition: 0.1s ease;

  svg,
  path {
    color: var(--palette-error) !important;
    fill: var(--palette-error) !important;
  }

  &:hover {
    svg,
    path {
      fill: var(--palette-error) !important;
      color: var(--palette-error) !important;
    }
  }
}
