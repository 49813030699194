.addScrollStyles::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.addScrollStyles::-webkit-scrollbar-thumb {
  border-radius: 23px;
  background-color: var(--global-scroll-background-color);
}

.addScrollStyles::-webkit-scrollbar-thumb:hover {
  background-color: var(--global-scroll-background-hover-color);
}

.addScrollStyles::-webkit-scrollbar-thumb:active {
  background-color: var(--global-scroll-background-active-color);
}

/* make element disappear */
.deleteElement {
  display: none !important;
}
