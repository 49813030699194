.name {
  font-weight: 600;
}

.tableRow {
  cursor: pointer;
}

.esp {
  font-weight: 600;
  text-transform: capitalize;
}

.downloadButton {
  width: 40px;
  height: 40px;
  background-color: #fefefe;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: 0.2s ease;

  svg {
    color: #4f4f4f;
  }

  &:hover {
    background-color: #f7f7f7;
  }
}

.cellRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
