.container {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background: var(--palette-default);
  border: 1px solid var(--palette-border-primary);
  box-shadow: var(--palette-box-shadow);
}

.projectDescription {
  color: var(--palette-text-secondary);
  font-size: 12px;
  margin: 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 0 0 8px 0;
}

.step {
  width: 30px;
  height: 30px;
  border: 1px solid #dadada;
  border-radius: 4px;
  font-weight: bolder;
  font-size: 14px;
  display: grid;
  place-items: center;
  background-color: #fafafa;
  color: var(--palette-text-secondary);
  flex-shrink: 0;
}

.selectGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
