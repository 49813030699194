@import url("non.geist");

.contentWrapper {
  width: 100%;
  padding: 26px 48px 28px;
}

.container {
  display: flex;
  align-items: center;

  width: 100%;
  column-gap: 24px;
}

.spacer {
  flex: 1 1 auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.nameRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.title {
  display: flex;
  align-items: center;

  color: var(--palette-text-default);
  font-family: "Geist Variable", sans-serif;
  font-weight: 800;

  font-size: 30px;
  font-style: normal;
}

.subtitle {
  font-size: 14px;
  color: var(--palette-text-secondary);
  line-height: 1.2;
  margin: 3px 0 0 0;
}

.container button {
  width: 42px;
  height: 42px !important;
  padding: 0;
}

/* Menu */
.menu {
  display: grid;
  padding: 0;
}

.name {
  padding: 8px 12px;

  span {
    color: var(--palette-text-secondary);
  }
}

.item {
  padding: 12px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border: 0;
  border-top: 1px solid var(--palette-tertiary-disabled);
  color: var(--palette-text-default);
  box-shadow: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #fafafa;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.triggerContent {
  padding: 0 !important;
}

.item:hover {
}

.item:focus,
.item:active {
  color: var(--palette-primary-disabled);
}

/* Hamburger button */
.container .hamburger {
  display: none;
}

@media (max-width: 992px) {
  .container {
    background-color: var(--palette-default);
  }

  .container .hamburger {
    display: flex;
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    padding: 26px 24px 28px;
  }

  .container {
    column-gap: 12px;
  }

  .title {
    font-size: 17px;
  }
}

@media (max-width: 450px) {
  .container .menuTrigger {
    display: none;
  }
}

@media (max-width: 520px) {
  .title {
    font-size: 15px;
  }
}

@media (max-width: 360px) {
  .title {
    font-size: 12px;
  }
}

.arrowBack {
  background-color: transparent;
  color: var(--palette-text-primary);
  transition: 0.2s ease;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--palette-text-primary);
    }
  }

  &:hover {
    //background-color: hsl(240 4.8% 95.9%);
    background-color: #ebebeb !important;

    svg {
      path {
        fill: var(--palette-text-primary);
      }
    }
  }

  &:focus {
    background-color: #eaeaea;
  }
}
