.wrapper {
  position: relative;
  display: block;
}

@keyframes skeleton {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.skeletonWrapperActive {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    background-image: linear-gradient(270deg, #fafafa, #eaeaea, #eaeaea, #fafafa);
    background-size: 400% 100%;
    animation: skeleton 8s ease-in-out infinite;
    opacity: 1;
    visibility: visible;
    transition-timing-function: ease;
    transition-duration: 1s;
    transition-delay: 0s;
  }
}

.skeletonWrapperHidden {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    background-image: linear-gradient(270deg, #fafafa, #eaeaea, #eaeaea, #fafafa);
    background-size: 400% 100%;
    animation: skeleton 8s ease-in-out infinite;
    opacity: 0;
    visibility: hidden;
    transition-timing-function: ease;
    transition-duration: 1s;
    transition-delay: 0s;
  }
}
