.loader {
  width: auto !important;
}

.badge {
  box-shadow: var(--palette-box-shadow);
}

.pending {
  svg {
    color: var(--palette-text-primary);
  }

  span {
    color: var(--palette-text-primary);
  }
}

.success {
  svg {
    color: var(--palette-success);
  }

  span {
    color: var(--palette-success);
  }
}

.finished {
  svg {
    color: #228b22;
  }

  span {
    color: #228b22;
  }
}

.failed {
  svg {
    color: var(--palette-error);
  }

  span {
    color: var(--palette-error);
  }
}

.button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 !important;
}
