.title {
  font-size: 22px;
  font-weight: 500;
}

.actions {
  display: flex;
  justify-content: flex-end;

  button:first-of-type {
    margin-right: 10px;
  }
}

.labelDescription {
  font-size: 12px;
  color: var(--palette-text-secondary);
  margin: 0 0 12px 0;
}

.salesforceLabel {
  @extend .labelDescription;
  margin: 0;
}

.row {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
}

.card {
  height: 40px;
  padding: 0 24px;
  border-radius: 12px;
  border: 1px solid var(--palette-border-primary);
  box-shadow: var(--palette-box-shadow);
  background-color: #fff;
  cursor: pointer;
  transition: 0.2s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  justify-content: center;
  color: var(--palette-text-secondary);

  &:hover {
    background-color: var(--palette-border-primary);
  }

  svg,
  path {
    fill: var(--palette-primary);
  }

  span {
    font-size: 12px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 14px;
}

.hint {
  margin-top: 12px;
}

.labelsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0;
}

.labelRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
}

.labelSpan {
  font-size: 11px;
  color: var(--palette-text-secondary);
  margin: 0;
}
