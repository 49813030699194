.name {
  font-weight: 600;
}

.tableRow {
  //cursor: pointer;
}

.terminateButton {
  padding: 0 12px !important;
  background-color: #ef4444 !important;

  &:hover {
    background-color: #ef4444e6 !important;
  }

  &:active {
    background-color: #ef4444e6 !important;
  }

  &:focus {
    background-color: #ef4444 !important;
  }
}

.cellRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
