.title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 12px;
}

.description {
  font-size: 12px;
  color: var(--palette-text-secondary);
  margin: 0 0 4px 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 14px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  align-items: center;
}
