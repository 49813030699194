.sidebarWrapper.override {
  width: auto !important;
}

.closeSidebarBtn {
  position: absolute;
  top: 10px;

  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px;

  cursor: pointer;

  border: none;

  background-color: transparent;
  appearance: none;
}

.closeSidebarBtn::-moz-focus-inner {
  border: 0;
}

.closeIcon {
  display: block;

  width: 13px;
  height: 13px;
}

.backdrop {
  display: none;
}

.bottomContent {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
  margin: 0 -17px;
  padding-left: 8px;
  flex-shrink: 0;

  /*display: none;*/

  margin-top: auto;
}

.sidebarItem {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  border: none;
  padding: 12px 17px;
  color: var(--palette-grey-active);
  outline: none;
  background-color: transparent;
  appearance: none;
  transition: all 0.3s ease;

  svg {
    width: 16px;
    height: 16px;
    color: var(--palette-primary);
    flex-shrink: 0;
  }

  &:hover {
    background-color: #fafafa;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

.sidebarItemWideGap {
  gap: 32px !important;
}

.changelogButton {
  @extend .sidebarItem;

  display: flex;
  flex-direction: row;
  gap: 12px;
  color: var(--palette-primary);

  svg {
    width: 20px;
    height: 20px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-items: flex-start;

    span {
      font-size: 10px;
      color: var(--palette-text-secondary);
    }
  }
}

/* toggler */

.toggler {
  position: absolute;
  top: 78px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  padding: 4px;

  cursor: pointer;
  transition: transform ease-in-out 0.4s;
  transform: rotate(0);

  border: none;
  border-radius: 50%;
  background: var(--sidebar-toggler-background);
}

.toggler.left {
  right: -9px;

  transform: rotate(180deg);
}

.toggler.collapsed {
  transform: rotate(0);
}

.menuCollapsed {
  /*visibility: hidden !important;*/
}

.togglerImage {
  width: 100%;
}

/* Logo */

.logo {
  display: grid;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;

  margin-bottom: 42px;

  cursor: pointer;

  color: var(--palette-primary);

  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  grid-template-columns: auto 1fr;
  gap: 12px;
}

.logo img {
  width: 36px;
  height: 36px;
}

.logo:hover {
  opacity: 0.8;
}

/* Menu */

.menu {
  display: grid;
  gap: 22px;
  //height: 100vh;

  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 12px;

  padding-left: 8px;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cacaca;
    border-radius: 4px;
  }
}

/* Link */

.link {
  position: relative;

  display: grid;
  align-items: center;

  box-sizing: border-box;
  width: 100%;

  white-space: nowrap;
  text-decoration: none;

  color: var(--palette-text-secondary);

  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  grid-template-columns: 20px 1fr;
  gap: 8px;
}

.link:hover,
.link:focus {
  text-decoration: underline;
}

.link img {
  filter: grayscale(100%) brightness(150%);
}

.link.collapsed .linkText {
  visibility: hidden;

  width: 0;
}

/* Link active */

.activeLink {
  position: relative;

  color: var(--palette-text-default);
}

.activeLink img {
  filter: none;

  opacity: 1;
}

@media (max-width: 992px) {
  .sidebarWrapper.override {
    position: fixed;
    z-index: 6;
  }

  .sidebarWrapper {
    top: 0;
    bottom: 0;
    left: 0;
  }

  .sidebarWrapper.collapsed {
    transform: translateX(-100%);
  }

  .backdrop {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;
    visibility: visible;

    transition-duration: 0.4s;

    transition-property: opacity, visibility;

    opacity: 1;

    background-color: rgba(0, 21, 97, 0.18);
  }

  .backdrop.collapsed {
    visibility: hidden;

    opacity: 0;
  }

  .link {
    font-size: 13px;
  }
}

.heiserLogo {
  width: 30px;
  height: 30px;
}

.subMenuTrigger {
  position: relative;

  display: grid;
  align-items: center;

  box-sizing: border-box;
  width: 100%;

  white-space: nowrap;
  text-decoration: none;

  color: var(--palette-text-secondary);

  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  grid-template-columns: 20px 1fr 20px;
  gap: 8px;
}

.subMenuWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  margin-left: 10px;
}

.subMenuItem {
  //margin-left: 20px;
}

.subMenuContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
