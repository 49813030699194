.select {
  max-width: 200px;

  input {
    height: 32px;
  }
}

.filters {
  //margin-top: 12px;
}
