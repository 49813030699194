.container {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background: var(--palette-default);
  border: 1px solid var(--palette-border-primary);
  box-shadow: var(--palette-box-shadow);
}

.title {
  margin-top: 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--palette-text-secondary);
}

.buttons {
  display: flex;
  justify-content: flex-end;

  margin-top: 30px;

  button:last-child {
    margin-left: 15px;
  }
}

.textareaWrapper {
  height: calc(100% - 50px) !important;
}

.textarea {
  min-height: 160px;
  height: 100% !important;
}

.projectDescription {
  margin: 0 0 8px 0;
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 32px;
  gap: 32px;

  @media screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
}
