.wrapper {
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    align-items: flex-start;
  }
}

.container {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background: var(--palette-default);
  border: 1px solid var(--palette-border-primary);
  box-shadow: var(--palette-box-shadow);
}

.projectDescription {
  color: var(--palette-text-secondary);
  font-size: 12px;
  margin: 0;
}

.noContentText {
  font-size: 12px;
  color: var(--palette-text-secondary);
  font-weight: 700;
}

.column {
  display: flex;
  flex-direction: column;
}

.projectSelect {
  margin-top: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 0 0 8px 0;
}

.step {
  width: 30px;
  height: 30px;
  border: 1px solid #dadada;
  border-radius: 4px;
  font-weight: bolder;
  font-size: 14px;
  display: grid;
  place-items: center;
  background-color: #fafafa;
  color: var(--palette-text-secondary);
  flex-shrink: 0;
}

.selectGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  &:last-child {
    margin-left: 15px;
  }
}

.alert {
  gap: 12px;
  grid-column: 1/3;
  background-color: var(--palette-error-transparent);
  color: var(--palette-error);
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;

  svg {
    flex-shrink: 0;
  }
}
