.deleteButton {
  background-color: #fafafa;
  border: 1px solid var(--palette-error-transparent);
  height: 28px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 12px;
  gap: 6px;
  color: var(--palette-error);

  &:hover {
    background-color: #fafafa;
  }

  &:focus {
    background-color: #eaeaea;
  }
}
