.description {
  margin: 0 0 8px 0;
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.wrapper {
  margin-top: 12px;
}

.container {
  margin-top: 24px;
}

.input {
  margin-top: 12px;
}

.tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  &:first-child {
    border-top-left-radius: 0 !important;
  }
}

.tabButton {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 1px solid #eaeaea;
}
