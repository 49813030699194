.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 24px;

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
}

.dateRange {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.tabs {
  border: 1px solid #e7e7e7;
}

.selectsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.select {
  max-width: 200px;

  input {
    height: 32px;
  }
}

.dateRangeInput {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  input {
    height: 32px;
  }

  &:first-child {
    align-items: center;
  }
}

.icon {
  color: var(--palette-text-secondary);
}

.tabSmallButton {
  min-width: 50px;
}

.tabButton {
  min-width: 80px;
}

.drawer {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
}

.drawerContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;

  p {
    margin: 0;
  }
}

.drawerDate {
  padding: 6px 0;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.codeBlock {
  padding: 12px;
  background-color: var(--palette-grey-lightly);
  border-radius: 12px;
  overflow: auto;

  code {
    pre {
      font-family: monospace;
    }
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.description {
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.fileBox {
  border: 1px solid #eaeaea;
  border-radius: 12px;
  background-color: var(--palette-grey-lightly);
  padding: 6px 12px;
  color: var(--palette-text-secondary);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.fileName {
  font-size: 14px;
  font-weight: 500;
  color: var(--palette-text-primary);
}

.fileSize {
  font-size: 12px;
}

.download {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  border-radius: 8px;
  background-color: #fefefe;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  transition: 0.2s ease;
  flex-shrink: 0;

  &:hover {
    background-color: #fdfdfd;
  }
}
