.sidebar {
  position: relative;

  width: 50%;
  height: 100vh;

  @media (max-width: 992px) {
    display: none;
    //width: 100%;
  }
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 48px;
  padding-bottom: 59px;
  padding-left: 36px;
}

.info {
  display: flex;

  color: var(--palette-text-default);
}

.option {
  margin-right: 80px;

  color: var(--palette-text-default);

  font-size: 12px;
}
