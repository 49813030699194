.ticker-view {
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  margin: 12px 0;
}

.number-placeholder {
  visibility: hidden;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
}

.ticker-column-container {
  position: relative;
}

.ticker-column {
  position: absolute;
  height: 1000%;
  bottom: 0;
}

.ticker-digit {
  width: auto;
  height: 24px;
  line-height: 24px;
}

.ticker-column.increase {
  animation: pulseGreen 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
}

.ticker-column.decrease {
  animation: pulseRed 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
}

@keyframes pulseGreen {
  0%,
  100% {
    color: inherit;
  }

  50% {
    color: #32cd32;
  }
}

@keyframes pulseRed {
  0%,
  100% {
    color: inherit;
  }

  50% {
    color: #fe6862;
  }
}
