@import url("non.geist");

.container {
  display: grid;
  place-items: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.title {
  display: flex;
  align-items: center;
  margin: 0;

  color: var(--palette-text-default);
  font-family: "Geist Variable", sans-serif;
  font-weight: 800;

  font-size: 30px;
  font-style: normal;
}

.link {
  text-decoration: none !important;
  margin-top: 24px;
}
