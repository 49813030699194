.rowWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  color: var(--palette-text-secondary);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.terminateButton {
  padding: 0 12px !important;
  background-color: #ef4444 !important;
  margin-top: 6px;

  &:hover {
    background-color: #ef4444e6 !important;
  }

  &:active {
    background-color: #ef4444e6 !important;
  }

  &:focus {
    background-color: #ef4444 !important;
  }
}

.details {
  margin-top: 24px;
}

.navbar {
  align-items: flex-start !important;
}

.buttonWrapper {
  button {
    height: 30px !important;
  }
}

.progressWrapper {
  margin: 12px 0;
}
