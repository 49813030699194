.row {
  cursor: pointer;
}

.name {
  font-weight: 600;
  word-break: break-word !important;
}

.switch {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
