.drawer {
  @media screen and (min-width: 1024px) {
    max-width: 450px !important;
  }
}

.container {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 24px;
}

.jsonWrapper {
  background-color: var(--body-background-color);
  border-radius: 12px;
  padding: 12px;
  overflow: auto;
}

.json {
  white-space: pre-wrap;
}
