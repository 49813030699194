@import url("non.geist");

.tour {
  z-index: 10000000;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.navbar {
  padding-bottom: 0 !important;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.boxContainer {
  width: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;
  //grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-template-rows: 156px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: 1320px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media screen and (min-width: 1700px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.title {
  display: flex;
  align-items: center;

  color: var(--palette-text-default);
  font-family: "Geist Variable", sans-serif;
  font-weight: 800;

  font-size: 30px;
  font-style: normal;
  margin: 0;
}

.content {
  width: 100%;
  display: grid;
  gap: 12px;
  margin-top: 24px;
  //flex-grow: 1;
  //flex-shrink: 1;
  flex-basis: 0;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

.box {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background: var(--palette-default);
  border: 1px solid var(--palette-border-primary);
  box-shadow: var(--palette-box-shadow);
}

.statusesWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.completed {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  color: var(--palette-success);
}

.failed {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  color: var(--palette-error);
}

.waiting {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  color: var(--palette-text-secondary);
}

.logo {
  width: 36px;
  height: 36px;
}

:global(.recharts-yAxis .recharts-cartesian-axis-tick-value) {
  font-size: 10px;
  fill: var(--palette-text-secondary);
}

:global(.recharts-xAxis .recharts-cartesian-axis-tick-value) {
  font-size: 10px;
  fill: var(--palette-text-secondary);
}

:global(.recharts-cartesian-grid-horizontal line) {
  stroke: var(--palette-tertiary-transparent);

  opacity: 0.4;
}

:global(.recharts-cartesian-grid-vertical line) {
  stroke: var(--palette-tertiary-transparent);

  opacity: 0.4;
}

:global(.recharts-layer.recharts-cartesian-axis line) {
  stroke: var(--palette-tertiary-transparent);
}

:global(.recharts-reference-line line) {
  stroke: var(--palette-tertiary-transparent);
}
