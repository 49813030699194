.title {
  font-size: 22px;
  font-weight: 500;
}

.content {
  margin: 12px 0;
}

.modal {
  padding-top: 12px;
}
