.navbar {
  padding-bottom: 0 !important;
}

.tabsWrapper {
  max-width: 300px;
  margin-bottom: 12px;
}

.tabs {
  width: 100%;
  border: 1px solid #eaeaea;
}
