.row {
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 !important;
  max-height: 46px !important;
}

.name {
  font-weight: 600;

  span {
    color: var(--palette-text-secondary);
    font-size: 10px;
    font-weight: 400 !important;
  }
}

.finished {
  svg {
    color: #50c878;
  }

  span {
    color: #50c878;
    font-weight: 500;
    font-size: 12px;
  }
}

.completed {
  svg {
    color: var(--palette-success);
  }

  span {
    color: var(--palette-success);
    font-weight: 500;
    font-size: 12px;
  }
}

.failed {
  svg {
    color: var(--palette-error);
  }

  span {
    color: var(--palette-error);
    font-weight: 500;
    font-size: 12px;
  }
}

.pending {
  svg {
    color: var(--palette-grey-active);
  }

  span {
    color: var(--palette-grey-active);
    font-weight: 500;
    font-size: 12px;
  }
}
