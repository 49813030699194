.container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.item {
  color: var(--palette-text-secondary);
  font-size: 11px;
}

.link {
  color: var(--palette-text-primary);
  font-size: 11px;
  text-decoration: none;
}
