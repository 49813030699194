.row {
  cursor: pointer;
}

.name {
  font-weight: 600;
}

.switch {
  display: flex;
  align-items: center;
}
