.selectButton {
  background-color: #fafafa;
  border: 1px solid var(--palette-info-transparent);
  height: 28px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 12px;
  gap: 6px;
  color: var(--palette-info);
  transition: 0.3s ease;

  &:hover {
    background-color: #fafafa;
  }

  span {
    font-size: 12px;
  }
}

.selectedButton {
  border: 1px solid var(--palette-info-hover);
}
