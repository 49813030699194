.container {
  width: 100%;
  padding: 6px 24px 12px 24px;
  border-radius: 16px;
  background: var(--palette-default);
  border: 1px solid var(--palette-border-primary);
  box-shadow: var(--palette-box-shadow);
  display: grid;
  grid-template-rows: 1fr 40px;
  overflow: auto;
}

.headerWrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 12px;
  margin-bottom: 16px;
}

.label {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchWrapper {
  max-width: 300px;
}

.filtersWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tabs {
  margin: 12px 0;

  @media screen and (min-width: 1200px) {
    margin: 0;
  }
}

.search {
  div {
    height: 32px !important;

    input {
      height: 32px !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.content {
  width: 100%;
  margin-top: 6px;
}

.subtitle {
  font-size: 12px;
  color: var(--palette-text-secondary);
  line-height: 1.2;
  margin: 3px 0 0 0;
}

.pagination {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 6px;
}

.table {
  margin-top: 12px;
  border-radius: 8px;
}

.tableContent {
  row-gap: 0 !important;
  min-height: unset !important;
}
