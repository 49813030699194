.wrapper {
  width: 100%;
  margin: 12px 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}

.input {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.detailsWrapper {
  width: 100%;
  height: 50px;
  display: grid;
  place-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  span {
    color: var(--palette-text-secondary);
  }
}

.errorWrapper {
  @extend .detailsWrapper;

  div {
    color: var(--palette-error);
  }

  span {
    color: var(--palette-error);
  }
}

.pagination {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 12px;
}

.removeButton {
  background-color: var(--palette-error-transparent) !important;
  color: var(--palette-error) !important;
  border-radius: 8px;
  display: grid;
  place-items: center;
  width: 32px !important;
  height: 32px !important;
  margin-top: 8px;
  transition: 0.1s ease;

  svg,
  path {
    color: var(--palette-error) !important;
    fill: var(--palette-error) !important;
  }

  &:hover {
    svg,
    path {
      fill: var(--palette-error) !important;
      color: var(--palette-error) !important;
    }
  }
}

.search {
  margin-bottom: 12px;
}

.list {
  border: 1px solid #fafafa !important;
  border-radius: 12px;
}

.listContent {
  border: 1px solid #fafafa !important;
}

.listHeader {
  background-color: #fafafa !important;
}
